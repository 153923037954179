<template>
  <v-app-bar app dense prominent color="white">
    <v-container fluid>
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <div style="position: relative;">
            <router-link to="/">
              <img src="@/assets/coff3.png" class="logo" alt="Mining Execution System" />
            </router-link>
            <div class="logo-caption caption">Mining Execution System</div>
          </div>

          <v-menu v-if="isTablet" close-on-content-click offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-app-bar-nav-icon @click="$emit('toggle-drawer')" v-bind="attrs" v-on="on" />
            </template>
          </v-menu>
        </div>
        <v-spacer />
        <v-btn-toggle v-if="!isTablet && user" color="primary" dense group class="nav-menu">
          <v-menu v-for="(item, index) in navMenu" :key="index" offset-y open-on-hover attach>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on" @click="navigate(item)">
                <v-icon>{{ item.icon }}</v-icon> {{ item.label }}
              </v-btn>
            </template>
            <v-sheet elevation="3">
              <v-list dense v-if="item.submenu">
                <v-list-item v-for="(subItem, subIndex) in item.submenu" :key="subIndex" @click="navigate(subItem)">
                  <v-list-item-icon>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-menu>
        </v-btn-toggle>
        <div v-if="user">
          <v-tooltip left attach>
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="cursor: pointer;" v-bind="attrs" v-on="on" @click="$emit('sign-out')">mdi-logout</v-icon>
            </template>
            <span>Signout</span>
          </v-tooltip>
        </div>
      </div>

      <div v-if="!isMaintenanceSelected && user" class="d-flex align-center justify-space-between grey lighten-4 mt-3">
        <v-system-bar style="height:34px;" window color="grey lighten-4">
          <div class="d-flex align-center">
            <v-icon>{{ sysbar.icon }}</v-icon>
            <span class="mr-2 overline" style="display: inline-block; white-space: nowrap; text-overflow: ellipsis;"
              :class="{ 'hidden-overflow': $vuetify.breakpoint.xs }" v-if="user && !isMaintenanceSelected">{{
                sysbar.title }}
              <span class="overline font-weight-black" v-if="bookmarkObj && bookmarkObj.projectId">
                {{ bookmarkObj.project.name }} - {{ bookmarkObj.project.nameEn }}
              </span>
            </span>
          </div>
        </v-system-bar>
        <v-btn x-small @click="$emit('open-pj-drawer')" class="mt-2" dark v-if="user">
          <v-icon color="white">mdi-label</v-icon> โครงการ
        </v-btn>
      </div>
      <div v-else-if="isMaintenanceSelected && user" class="d-flex flex-nowrap align-center grey lighten-4 mt-3"
        style="height: 34px;max-width: 100%; overflow-x: auto;">
        <!-- System Bar -->
        <v-system-bar class="d-none d-sm-flex" style="max-height: 32px; width: auto; flex-shrink: 0;" window
          color="grey lighten-4">
          <v-icon>{{ sysbar.icon }}</v-icon>
          <span :class="{ 'hidden-overflow': $vuetify.breakpoint.xs }"
            style="display: inline-block; white-space: nowrap; text-overflow: ellipsis;">
            {{ sysbar.title }}
          </span>
        </v-system-bar>

        <!-- Label and Chip Group -->
        <div class="d-flex flex-nowrap align-center" style="flex-grow: 1; overflow-x: auto; padding-left: 8px;">
          <v-chip-group v-model="mtProjectSelected" active-class="primary--text" multiple show-arrows
            class="chip-group-wrapper"
            style="display: flex; flex-wrap: nowrap; overflow-x: auto; white-space: nowrap; gap: 4px;">
            <v-chip filter outlined small v-for="item in systemBarData" :key="item.id" :value="item.id">
              <span class="font-sarabun text-truncate">{{ item.name }}</span>
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppBar',
  data() {
    return {
      isMaintenanceSelected: false,
      mtProjectSelected: [],

    };
  },
  props: {
    isTablet: Boolean,
    navMenu: Array,
    sysbar: Object,
    bookmarkObj: Object,
  },
  mounted() {
    this.getLocalStorage();
  },
  methods: {
    navigate(item) {

      this.$emit('update-sysbar', item.icon, item.label);
      if (item.link === '/report') {
        const route = this.$router.resolve({ path: '/report' });
        window.open(route.href, '_blank');
      }
      else if (item.link === '#home') {
        this.$router.push({ path: '/' });
      } else {
         this.$router.push({ path: item.link }).catch(() => { });        
      }
    },
    setLocalStorage(val) {
      localStorage.setItem("mtProjectSelected", JSON.stringify(val));

      this.$store.commit('utils/SET_SYSTEM_BAR_SELECTED', val);
    },

    getLocalStorage() {
      const storedValue = localStorage.getItem("mtProjectSelected");
      if (storedValue) {
        this.mtProjectSelected = JSON.parse(storedValue);
      }
    },
  },
  computed: {
    ...mapState('utils', ['loading', 'systemBarData', 'systemBarSelected']),
    ...mapState('authen', ['user']),
   
    
  },
  watch: {
    mtProjectSelected(val) {

      this.setLocalStorage(val);

    },

    sysbar: {
      handler(val) {
        this.isMaintenanceSelected = ['Preventive Maintenance', 'Corrective Maintenance'].includes(val?.title);
      },
      deep: true,
    },

    
  },
};
</script>

<style>
.logo {
  height: 40px;
  vertical-align: middle;
}

.logo-caption {
  position: absolute;
  width: 200px;
  margin-top: -8px;
  margin-left: 6px;
  font-size: 12px;
  color: #747272;
}

.nav-menu {
  display: flex;
  flex-wrap: wrap;
}

.nav-menu .v-btn {
  background: #ffffff;
  color: #676565 !important;
}

.v-toolbar__content {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.container {
  padding: 0px !important;
}

.chip-group-wrapper {
  max-width: 100%;
  overflow: hidden;
}


.hidden-overflow {
  overflow: hidden !important;
  max-width: 270px !important;
}


@media print {
  .v-app-bar {
    display: none !important;
    /* ซ่อน v-app-bar */
    box-shadow: none !important;
    background: transparent !important;
  }

  .v-main {
    padding-top: 0 !important;
    /* ลบ padding ที่ Vuetify เพิ่มมาเพื่อรองรับ v-app-bar */
    margin-top: 0 !important;
    /* ป้องกันช่องว่างเกิน */
  }
}
</style>