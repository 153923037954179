const cmPrint = {
    namespaced: true,
    state: {
        reportName: null,
        dataPrintDataAll: [],
        dataPrintDataStock: [],
        dataPrintHistory: [],
        // dataPrintMining: [],
        // dataproductionReport: [],

   
    },
    getters: {},
    mutations: {
        SET_REPORT_NAME(state,payload) {
            state.reportName = payload;        
        },
        SET_DATAALL(state, payload) {
            state.dataPrintDataAll = payload;
        },
        SET_DATASTOCK(state, payload) {
            state.dataPrintDataStock = payload;
        },
        SET_DATAHISTORY(state, payload) {
            state.dataPrintHistory = payload;
        },
        // SET_DATAMINING(state, payload) {
        //     state.dataPrintMining = payload;
        // },
        // SET_DATA_PRODUCTION_REPORT(state, payload) {
        //     state.dataproductionReport = payload;
        // },
    },
    actions: {
        async addDataPrintAll({ commit, dispatch }, payload) {
            console.log("print",payload)
            try {
                const item = payload;
                commit('SET_DATAALL', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addDataPrintStock({ commit, dispatch }, payload) {
            console.log("print",payload)
            try {
                const item = payload;
                commit('SET_DATASTOCK', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        async addHistory({ commit, dispatch }, payload) {
            try {
                const item = payload;
                
                commit('SET_DATAHISTORY', item);

                return;
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true });
            }
        },
        // async addDataMining({ commit, dispatch }, payload) {
        //     try {
        //         const item = payload;
        //         commit('SET_DATAMINING', item);

        //         return;
        //     } catch (e) {
        //         dispatch('utils/handleHttpError', e, { root: true });
        //     }
        // },
        // async addproductionReport({ commit, dispatch }, payload) {
        //     try {
        //         const item = payload;
        //         console.log(item);
        //         commit('SET_DATA_PRODUCTION_REPORT', item);

        //         return;
        //     } catch (e) {
        //         dispatch('utils/handleHttpError', e, { root: true });
        //     }
        // },
            
    }
}

export { cmPrint }