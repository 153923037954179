import { mesDataService } from "@/services/mesDataService";

const machine = {
    namespaced: true,
    state: {      
        dataByProject: [],
        machineCreateItem: null,
        machineUpdateItem: null,
        machineDeleteItem: null,
    },
    getters: {
        machinePmData: (state) => {
            return state.dataByProject.filter(obj => obj.active === 1)
        },

        dataActived: (state) => {
            return state.data.filter(obj => obj.active == 1)
        }
    },
    mutations: {

        LIST_MACHINE_BY_PROJECT(state, payload) {
            payload ? state.dataByProject = payload : []
        },
        UPDATE_MACHINE_BY_PROJECT(state, payload) {
            const newData = state.dataByProject.map(el => el.id === payload.id ? Object.assign(el, payload) : el)
            state.dataByProject = newData
        },
        CREATE_ITEM(state, item) {
            state.machineCreateItem = item ? item : null
        },
        UPDATE_ITEM(state, item) {
            state.machineUpdateItem = item ? item : null
        },     
        DELETE_ITEM(state, item) {
            state.machineDeleteItem = item ? item : null
        },   
    },
    actions: {


        async updatePMStatus({ dispatch, commit }, payload) {

            try {
                await mesDataService.machine.update(payload.id, payload)
                commit('UPDATE_MACHINE_BY_PROJECT', payload)

                dispatch('utils/alertUpdated', null, { root: true })

                return payload
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async list({ dispatch }) {

            try {
                const result = await mesDataService.machine.list();

                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

            return
        },
             
        async listByProjectArray({ dispatch, commit }, projectId) {
            try {
                const result = await mesDataService.machine.listByProjectArray(projectId);
                commit('LIST_MACHINE_BY_PROJECT', result.data)

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
       

    },
}

export { machine }