<template>
  <div v-if="!isMobile">
    <v-app>
      <app-bar @toggle-drawer="drawer = !drawer" :isTablet="isTablet" :user="user" :nav-menu="navMenu"
        @sign-out="signOutFn" @update-sysbar="updateSysbar" @open-pj-drawer="pjDrawer = true" :sysbar="sysbar"
        :bookmark-obj="bookmarkObj" />

      <navigation-drawer v-model="drawer" :isTablet="isTablet" :nav-menu="navMenu" @navigate="navigateTo" />

      <v-main class="page-wrapper">
        <v-container fluid>
          <project-drawer v-model="pjDrawer" :projects="projectRoleData" :project-selected="projectSelected"
            @update-project-selected="projectSelected = $event" />
          <alert-component></alert-component>
          <loading-component v-show="loading" />

          <router-view />
        </v-container>
      </v-main>

      <app-footer />

      <v-scale-transition v-if="!isTablet">
        <v-btn fab v-show="fab" v-scroll="onScroll" fixed bottom right color="secondary" @click="toTop" dark
          class="mb-10">
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-scale-transition>
    </v-app>
  </div>
  <div v-else class="mobile-message">
    <h2>Sorry, this app is not supported on Mobile Devices</h2>
    <p>To use this app, please use a computer or Tablet</p>
  </div>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import { utils } from '@/libs/utils';
import AppBar from './components/AppBar.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import ProjectDrawer from './components/ProjectDrawer.vue';
import AppFooter from './components/AppFooter.vue';
import AlertComponent from '@/components/system/AlertComponent.vue';
import LoadingComponent from '@/components/system/LoadingComponent.vue';

export default {
  name: 'AppLayout',
  components: { AppBar, NavigationDrawer, ProjectDrawer, AppFooter, AlertComponent, LoadingComponent },
  data() {
    return {
      fab: false,
      color: "",
      flat: null,
      isTablet: false,
      drawer: false,
      pjDrawer: false,
      navMenu: [
        { label: 'Dashboard', icon: 'mdi-chart-pie', link: '/' },
        { label: 'Production', icon: 'mdi-calendar-month', link: '/production' },
        {
          label: 'CMMS',
          icon: 'mdi-tools',
          submenu: [
            { label: 'Preventive Maintenance', icon: 'mdi-car-clock', link: '/maintenance' },
            { label: 'Corrective Maintenance', icon: 'mdi-car-wrench', link: '/maintenance/correctiveMaintenance' },
          ],
        },
        { label: 'Setting', icon: 'mdi-cog', link: '/setting' },
        { label: 'Report', icon: 'mdi-chart-box-outline', link: '/report' },
      ],
      sysbar: { icon: '', title: '' },
      projectSelected: null,
      projectRoleData: [],
      isMobile: null,

    };
  },
  async mounted() {
    await this.fetchUser();
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });

  },

  methods: {
    ...mapActions('authen', ['fetchUser']),
    ...mapActions('logLogin', { getLastLoginToday: 'getLastLoginToday', logLoginUpdate: 'update' }),
    ...mapActions('project', { projectList: 'list' }),
    ...mapActions('projectRoles', { findProjectRoles: 'findByMemberId' }),
    ...mapActions('bookmark', { createBookmark: 'create', updateBookmark: 'update', getBookmark: 'get' }),

    onResize() {
      this.isTablet = window.innerWidth >= 380 && window.innerWidth < 960;
      this.isMobile = window.innerWidth < 380;      
    },

    async navigateTo(item) {
      this.updateSysbar(item.icon, item.label);
      if (item.link === '/report') {
        const route = this.$router.resolve({ path: '/report' });
        window.open(route.href, '_blank');
      } else {
        this.$router.push({ path: item.link }).catch(() => { });
      }
    },

    async signOutFn() {
      this.updateSysbar('', '');
      const lastLoginObj = await this.getLastLoginToday();
      if (lastLoginObj) {
        const logUpdateObj = { id: lastLoginObj.id, logoutTimestamp: Date.now() };
        await this.logLoginUpdate(logUpdateObj);
      }
      await this.$store.dispatch('authen/signout');
    },

    async initializeUser(userObj) {
      this.projectList();
      const roleData = await this.findProjectRoles(userObj.id);
      this.projectRoleData = utils.sortThaiDictionary(
        roleData.map(role => ({ ...role, projectName: role.project.name })),
        'projectName'
      );
      await this.getBookmark(userObj.email);
      this.projectSelected = this.bookmarkObj?.projectId ?? (this.projectRoleData.length === 1 ? this.projectRoleData[0].projectId : null);
    },

    async updateBookmarkFn(projectId) {
      try {
        const payload = { projectId, lastLogin: new Date() };
        if (this.bookmarkObj) {
          await this.updateBookmark(payload);
        } else {
          await this.createBookmark(payload);
        }
        await this.getBookmark(this.user.email);
      } catch (e) {
        console.error('Bookmark update failed:', e);
      }
    },

    updateSysbar(icon, title) {
      this.sysbar.icon = icon;
      this.sysbar.title = title;

    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;

      this.fab = top > 60;

    },
    toTop() {
      this.$vuetify.goTo(0);
    },



  },
  computed: {
    ...mapState('authen', ['user']),
    ...mapState('bookmark', { bookmarkObj: 'data' }),
    ...mapState('utils', ['loading', 'systemBarData', 'systemBarSelected']),
  },
  watch: {
    user(newUser) {
      if (newUser) this.initializeUser(newUser);
    },
    projectSelected(newId) {
      if (this.user) this.updateBookmarkFn(newId);
    },
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },



  },
};
</script>

<style scoped>
.page-wrapper {
  min-height: 100vh;
  /* Ensures the page takes up full viewport height */
  display: flex;
  flex-direction: column;
}


.v-main {
  background-color: #f5f5f5;
  /* font-family: "Sarabun", sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
}

.mobile-message {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: center; 
  background-color: #141625;
  color: #fff;
}

.mobile-message p {
  margin-top: 16px;
}
</style>