import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import VueExcelXlsx from "vue-excel-xlsx";
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import exportingHighcharts from "highcharts/modules/exporting";
import accessibility from 'highcharts/modules/accessibility'
import timeline from 'highcharts/modules/timeline'
import networkgraph from 'highcharts/modules/networkgraph'
import Highcharts3D from 'highcharts/highcharts-3d'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'; // เปลี่ยนธีมตามต้องการ
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './assets/styles/global.css';


highchartsMore(Highcharts)
exportingHighcharts(Highcharts)
accessibility(Highcharts)
timeline(Highcharts)
Highcharts3D(Highcharts)
networkgraph(Highcharts)
Vue.config.productionTip = false
Vue.use(VueExcelXlsx)
Vue.use(HighchartsVue, {Highcharts})
Vue.use(PrimeVue);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
