<template>
    <v-navigation-drawer v-model="pjDrawer" app right temporary dark height="100%">
      <v-radio-group class="ml-2" :value="projectSelected" @change="$emit('update-project-selected', $event)" dense>
        <v-radio small color="orange" class="mr-2 small-radio font-sarabun" v-for="item in projects" :key="item.projectId"
          :label="item.project.name" :value="item.projectId" />
      </v-radio-group>
    </v-navigation-drawer>
  </template>
  
  <script>
  export default {
    name: 'ProjectDrawer',
    props: {
      projects: Array,
      value: Boolean, // v-model for pjDrawer
      projectSelected: Number, // prop for projectSelected
    },
    computed: {
      pjDrawer: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
    },
  };
  </script>
  
  <style>
  .small-radio {
    padding: 0;
  }
  .small-radio .v-label {
    font-size: 13px;
  }
  .small-radio .v-radio .v-icon {
    font-size: 13px;
  }
  .font-sarabun {
    font-family: 'Sarabun', sans-serif !important;
  }
  </style>