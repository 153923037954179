<template>
  <component :is="component">
    <slot />
  </component>
</template>

<script>



import VuebaseLayoutVue from './layouts/VuebaseLayout.vue';

export default {
  name: 'App',
  components: {
    VuebaseLayoutVue
  },

  data: () => ({    
  }),

 

  mounted() {
    document.body.style.zoom = "90%";
  },

  beforeDestroy() {
    document.body.style.zoom = "100%";
  },



  computed: {
    component() {
      return VuebaseLayoutVue
    }
  }

};
</script>


<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
/* ฟอร์มทางการ สำหรับปริ้น  */
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

</style>

