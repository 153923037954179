<template>
    <v-navigation-drawer v-model="drawer" app temporary v-if="isTablet" dark height="100%">
        <v-list dense>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="title">
                        <router-link to="/">
                            <img src="@/assets/coff3.png" class="logo" alt="Coff3" />
                        </router-link>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item v-for="(item, index) in navMenu" :key="index">
                <v-list-item-content v-if="!item.submenu" @click="$emit('navigate', item)">
                    <v-list-item-title class="pointer">
                        <v-icon left>{{ item.icon }}</v-icon> {{ item.label }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-group v-else :value="true" @click.stop>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="pointer">
                                <v-icon left>{{ item.icon }}</v-icon> {{ item.label }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item v-for="(subItem, subIndex) in item.submenu" :key="subIndex"
                        @click="$emit('navigate', subItem)">
                        <v-list-item-content>
                            <v-list-item-title class="pointer">
                                <v-icon left>{{ subItem.icon }}</v-icon> {{ subItem.label }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'NavigationDrawer',
    props: {
        isTablet: Boolean,
        navMenu: Array,
        value: Boolean, // v-model
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>

<style>

.logo {
    padding-top: 5px;
    height: 50px;
    width: auto;
}

.pointer {
    cursor: pointer;
}
</style>