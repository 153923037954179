
const executor = {
    namespaced: true,
    state: {       
        executorCreateItem: null,
        executorUpdateItem: null,
        executorDeleteItem: null,
    },   
    mutations: {
        CREATE_ITEM(state, item) {
            state.executorCreateItem = item ? item : null
        },
        UPDATE_ITEM(state, item) {
            state.executorUpdateItem = item ? item : null
        },     
        DELETE_ITEM(state, item) {
            state.executorDeleteItem = item ? item : null
        },   
    },
    actions: {}

}

export { executor }